<template>
    <div class="wrapper grid row-50">
        
        <ActionBar title="Users">
            <Button text="Create User" size="small" @click="createUser"/>
        </ActionBar>


        <div class="roles-content grid row-20">


            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search users..."/>

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Role</div>
                    <DropDownCheckbox v-model="_pageManager.query.role" placeholder="All" selectAttribute="_id" showAttribute="name" :options="roles"/>
                </div>

                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value" showAttribute="name" :options="[{ value: null, name: 'All' }, { value: 'active', name: 'Active' }, { value: 'pending', name: 'Pending' }, { value: 'removed', name: 'Removed' }]"/>
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetUsers'])">
                    <TableRow 
                        :size="columnsSizes" 
                        :key="item._id" 
                        v-for="(item, key) of _pageManager.list" 
                        @dblclick="saveUser(item, key)"
                        :menu="getUserMenu(item)"
                        @edit="saveUser(item, key)"
                        @remove="removeUser(item)"
                        @restore="restoreUser(item)"
                    >
                        <UserItem :item="item"/>
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetUsers'])" class="spinner-flex"><Spinner/></div>
                    <div v-else><NoDataMessage text="Nothing found"/></div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import UserItem from '../../components/Users/UserItem.vue'

import AdminSaveUserVue from '../../components/Users/AdminSaveUser.vue'
import AdminRegisterUser from '../../components/Users/AdminRegisterUser.vue'


    export default {
        mixins: [PaginationJS],
        components: {
            Pagination,
            UserItem
        },
        data() {
            return {
                tableHead: [
                    { name: 'Display name' },
                    { name: 'Name' },
                    { name: 'Last name' },
                    { name: 'Role' },
                    { name: 'Email address' },
                    { name: 'Date of birth' },
                    { name: 'Status' },
                    { name: '' },
                ],
                columnsSizes: [ 'minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 120px)','minmax(50px, 120px)','34px' ],
                roles: []
            }
        },
        watch: {
            "_pageManager.query.search": {
                deep: true,
                handler() {
                    this._pageManager.query.page = 1;
                }
            },
            "_pageManager.query": {
                deep: true,
                handler() {
                    this.getUsers();
                }
            }
        },
        methods: {
            getUserMenu(item) {
                let array = [{ name: 'Edit user', emit: 'edit' }];
                
                if(item.removed) {
                    array.push({ name: 'Restore user', emit: 'restore' })
                } else {
                    array.push({ name: 'Remove user', emit: 'remove', type: 'remove' })
                }

                return array;
            },
            getListOfRoles() {
                this.ajax('GetListOfRoles', {
                    url: '/roles/list',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.roles = body;
                });
            },
            getUsers() {
                let query = new URLSearchParams(this._pageManager.query).toString();

                this.ajax('GetUsers', {
                    url: '/users?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this._pageManager.pages = body.pages;
                        this._pageManager.count = body.count;
                        this._pageManager.list = body.list;
                        this._pageManager.page = body.page;
                        this._pageManager.hasNextPage = body.hasNextPage;
                        this._pageManager.hasPrevPage = body.hasPrevPage;
                    }
                });
            },
            async removeUser(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this user?');
                if(!confirmRemove) return;

                await this.ajax('RemoveUser', {
                    url: `/users/remove/${item._id}`,
                    method: 'PUT',
                }, (err, body) => { });

                item.removed = true;
                item.email = null;
                this.getUsers();
            },
            async restoreUser(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to restore account for this user?');
                if(!confirmRemove) return;

                await this.ajax('Restore User', {
                    url: `/users/restore/${item._id}`,
                    method: 'PUT',
                }, (err, body) => { });

                item.removed = false;
                this.getUsers();
            },
            saveUser(user = {}) {

                user = JSON.parse(JSON.stringify(user));

                let modal = this.$ShowModal({
                    title: 'Update User',
                    description: 'Update user details',
                    component: AdminSaveUserVue,
                    props: {
                        item: user,
                        errors: {}
                    }
                });

                modal.onClose(() => {
                    this.getUsers();
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('SaveUser', {
                        url: '/users',
                        method: 'POST',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                    });

                    modal.processing(false);
                });
            },
            createUser() {

                let modal = this.$ShowModal({
                    title: 'Create User',
                    description: 'Enter details about a new user',
                    component: AdminRegisterUser,
                    props: {
                        item: {},
                        errors: {}
                    }
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    let registerType = props.selectedRegisterType || 'invite';
                    modal.setProp('errors', {});
                    await this.ajax('HandleUserRegister', {
                        url: `/users/${registerType}`,
                        method: 'POST',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }

                        modal.close();
                        this.saveUser(body);
                        this.getUsers();
                    });

                    modal.processing(false);
                });

            }
        },
        mounted() {
            this.getListOfRoles();
            this.getUsers();
        }
    }
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>