<template>
    <div class="change-password-wrapper grid row-15">
        <Input v-model="item.password" name="New password" placeholder="New password" type="password" :error="errors.password"/>
        <Input v-model="item.repeatPassword" name="Repeat password" placeholder="Repeat password" type="password" :error="errors.repeatPassword"/>
    </div>
</template>

<script>
    export default {
        props: ['item', 'errors']
    }
</script>

<style lang="scss" scoped>
.change-password-wrapper {
    padding: 20px;
}
</style>