<template>
    <div class="save-user-wrapper">

        <div v-if="isLoading(['GetListOfRoles', 'GetUsersDetails', 'GetRoleUsersProperties', 'UploadingProfileImage', 'HandleRemoveUser'])" class="spinner-flex"><Spinner/></div>

        <div class=" grid row-15" v-if="item">
            <h3>Main details</h3>

            <div class="profile-template">
                <div class="image" :style="[ `background-image: url('${userImage}')` ]" @click="uploadProfileImage"></div>
                <div class="content">
                    <div class="name text-overflow">{{ item.name }} {{ item.lastname }}</div>
                    <div class="email text-overflow">{{ item.email }}</div>
                    <div class="password">
                        <a href="" @click.prevent="copyInvitationLink" v-if="item.pending">Copy invitation URL</a>
                        <a href="" @click.prevent="changeUserPassword" v-else>Change password</a>
                        &nbsp;
                        <span>
                            <a href="" @click.prevent="restoreUser" v-if="item.removed">Restore access</a>
                            <a href="" @click.prevent="removeUser"  class="removeLink" v-else>Remove</a>
                        </span>
                    </div>
                </div>
            </div>

            <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter name"/>
            <Input name="Last name" v-model="item.lastname" :error="errors.lastname" placeholder="Enter last name"/>
            <Input name="Display name" v-model="item.displayName" :error="errors.displayName" placeholder="Enter display name"/>
            <InputDropDownDatepicker v-model="item.dob" :error="errors.dob" placeholder="Select date of birth" name="Date of birth"/>

            <Input name="Email address" v-model="item.email" :error="errors.email" placeholder="Enter email address" description="Email address is used to log in"/>

            <div class="switch-item">
                <div class="name">Administrator</div>
                <Switch v-model="item.isAdmin"/>
            </div>

            <InputDropDownList placeholder="Select role" name="Role" v-model="item.role" :error="errors.role" showAttribute="name" selectAttribute="_id" :options="roles"/>
            <Input name="Salary" v-model="item.salary" :error="errors.salary" placeholder="Enter salary"/>


            <div v-if="(Object.keys(otherUserProperties).length > 0)">
                <div :key="name" v-for="(userProps, name) of otherUserProperties" class="grid row-15">
                    <h3>{{ name }}</h3>
                    <div class="fields grid row-15">
                        <div :key="key" v-for="(userProp, key) of userProps">
                            <Input :name="userProp.name" v-model="item[userProp.attribute]" :error="errors[userProp.attribute]" :placeholder="userProp.name"/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilePicker from '../../mixins/FilePicker'
import ChangePassword from './ChangePassword.vue'

    export default {
        mixins: [FilePicker],
        props: ['modal', 'item', 'errors'],
        data() {
            return {
                roles: [],
                otherUserProperties: {}
            }
        },
        computed: {
            ...mapGetters(['user']),
            userImage() {
                return this.item.image ? this.item.image : this.DEFAULTS.USER.IMAGE;
            }
        },
        watch: {
            "item.role"() {
                this.getRoleUsersProperties();
            }
        },
        methods: {
            getListOfRoles() {
                this.ajax('GetListOfRoles', {
                    url: '/roles/list',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.roles = body;
                });
            },
            getUserById() {
                if(!this.item._id) {
                    this.modal.close();
                    return;
                }
                this.ajax('GetUsersDetails', {
                    url: `/users/${this.item._id}`,
                    method: 'GET',
                }, async (err, body) => {
                    if(err) {
                        this.modal.close();
                        this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    if(body.role && body.role._id) body.role = body.role._id;
                    this.modal.setProp('item', body);
                });
            },
            getRoleUsersProperties() {
                if(!this.item.role) {
                    this.otherUserProperties = {};
                    return;
                }
                this.ajax('GetRoleUsersProperties', {
                    url: `/roles/${this.item.role}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.otherUserProperties = body;
                });
            },
            uploadProfileImage() {
                this.createFilePicker("image/png, image/jpeg", (image) => {
                    
                    var data = new FormData();
                    data.append('image', image);
                    
                    this.ajax('UploadingProfileImage', {
                        url: `/users/image/${this.item._id}`,
                        method: 'POST',
                        data: data
                    }, (err, body) => {
                        if(err) {
                            this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                            return;
                        }
                        this.item.image = body.image;
                        if(this.item._id === this.user._id) {
                            this.user.changeImage(body.image);
                        }
                    });

                });
            },
            changeUserPassword() {

                let modal = this.$ShowModal({
                    title: 'Change password',
                    description: 'Password must be at least 8 characters long',
                    component: ChangePassword,
                    props: {
                        item: {},
                        errors: {}
                    }
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('SaveUser', {
                        url: `/users/password/${this.item._id}`,
                        method: 'PUT',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                        this.$ShowAlert("Password has been changed successfully.");
                    });

                    modal.processing(false);
                });
            },
            async removeUser() {
                let isConfirm = await this.$ShowConfirm("Do you really wish to remove this user?");
                if(!isConfirm) return;
                this.ajax('HandleRemoveUser', {
                    url: `/users/remove/${this.item._id}`,
                    method: 'PUT'
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    if(this.item.pending) {
                        this.modal.close();
                    } else {
                        this.item.removed = true;
                    }
                    this.$ShowAlert(body.message || "User has been removed and no longer has access.");
                });
            },
            async restoreUser() {
                let isConfirm = await this.$ShowConfirm("Do you really wish to restore this user?");
                if(!isConfirm) return;
                this.ajax('SaveUser', {
                    url: `/users/restore/${this.item._id}`,
                    method: 'PUT'
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.item.removed = false;
                    this.$ShowAlert(body.message || "User has been restored and now has access to an account.");
                });
            },
            copyInvitationLink() {
                let url = this.SYSTEM_URL + '/user/invitation/' + this.item.invitationId;
                this.COPY_TEXT(url);
            }
        },
        mounted() {
            this.getListOfRoles();
            this.getUserById();
        }
    }
</script>

<style lang="scss" scoped>
.save-user-wrapper {
    padding: 20px;
    position: relative;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.profile-template {
    display: grid;
    grid-template-columns: 70px minmax(0, 1fr);
    column-gap: 20px;
    align-items: center;
    .image {
        width: 100%;
        padding-top: 100%;
        background-color: #ccc;
        background-size: cover;
        background-position: center;
        border-radius: 17px;
        cursor: pointer;
    }
    .content {
        .name {
            font-weight: 500;
            font-size: 14px;
        }
        .role {
            color: $textShade;
        }
    }
}

.password {
    margin-top: 10px;
}

.removeLink {
    color: $error;
}

</style>