<template>
    <div class="text-overflow">
        <div class="profile">
            <div class="image" :style="[`background-image: url('${userImage}')`]"></div>
            <div class="displayName"><a href="" @click.prevent>{{ displayName }}</a></div>
        </div>
    </div>
    <div class="text-overflow">{{ userName }}</div>
    <div class="text-overflow">{{ lastName }}</div>
    <div class="text-overflow">{{ role }}</div>
    <div class="text-overflow">{{ item.email }}</div>
    <div class="text-overflow">{{ dob }}</div>
    <div class="text-overflow user-status-holder"><span class="user-status" :class="[status.class]">{{ status.value }}</span></div>
</template>

<script>
import moment from 'moment';
    export default {
        props: ['item'],
        computed: {
            userName() {
                return this.item.pending ? 'Pending' : this.item.name;
            },
            lastName() {
                return this.item.pending ? 'Pending' : this.item.lastname;
            },
            userImage() {
                return this.item.image ? this.item.image : this.DEFAULTS.USER.IMAGE;
            },
            displayName() {
                if(this.item.pending) return 'Pending';
                return this.item.displayName ? this.item.displayName : this.item.name;
            },
            role() {
                return this.item.role ? this.item.role.name : '';
            },
            dob() {
                if(!this.item.dob) return '';
                let date = moment.utc(this.item.dob);
                if(date.isValid()) return date.format("MMM D, YYYY")
                return '';
            },
            status() {
                return this.item.removed ? { value: 'Removed', class: 'removed' } : this.item.pending ? { value: 'Invited', class: 'pending' } : { value: 'Active', class: 'active' };
            }
        }
    }
</script>

<style lang="scss" scoped>
.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 34px minmax(0, auto);
    column-gap: 15px;
    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 9px;
    }
}

.user-status-holder {
    display: flex;
}

.user-status {
    padding: 0 10px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 11px;
    font-size: 10px;
    &.active {
        color: #fff;
        background: $success;
    }
    &.removed {
        background: $error;
        color: #fff;
    }
    &.pending {
        background: $themeColor2;
        color: #fff;
    }
}
</style>