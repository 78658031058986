<template>
    <div class="save-user-wrapper">

        <div v-if="isLoading(['HandleUserRegister', 'GetListOfRoles'])" class="spinner-flex"><Spinner/></div>

        <div class="grid row-15" v-if="item">
            <h3>Main details</h3>

            <InputDropDownList placeholder="Select register type" name="Register type" v-model="selectedRegisterType" showAttribute="name" selectAttribute="value" :options="registerTypes"/>

            <div class="grid row-15" v-if="selectedRegisterType === 'register'">
                <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter name"/>
                <Input name="Last name" v-model="item.lastname" :error="errors.lastname" placeholder="Enter last name"/>
                <Input name="Display name" v-model="item.displayName" :error="errors.displayName" placeholder="Enter display name"/>
                <InputDropDownDatepicker v-model="item.dob" :error="errors.dob" placeholder="Select date of birth" name="Date of birth"/>
            </div>

            <Input name="Email address" v-model="item.email" :error="errors.email" placeholder="Enter email address" description="Email address is used to log in"/>

            <div class="grid row-15" v-if="selectedRegisterType === 'register'">
                <Input v-model="item.password" name="New password" placeholder="New password" type="password" :error="errors.password"/>
                <Input v-model="item.repeatPassword" name="Repeat password" placeholder="Repeat password" type="password" :error="errors.repeatPassword"/>
            </div>

            <div class="switch-item">
                <div class="name">Administrator</div>
                <Switch v-model="item.isAdmin"/>
            </div>

            <InputDropDownList placeholder="Select role" name="Role" v-model="item.role" :error="errors.role" showAttribute="name" selectAttribute="_id" :options="roles"/>

            <div v-if="(Object.keys(otherUserProperties).length > 0)">
                <div :key="name" v-for="(userProps, name) of otherUserProperties" class="grid row-15">
                    <h3>{{ name }}</h3>
                    <div class="fields grid row-15">
                        <div :key="key" v-for="(userProp, key) of userProps">
                            <Input :name="userProp.name" v-model="item[userProp.attribute]" :error="errors[userProp.attribute]" :placeholder="userProp.name"/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>

    export default {
        props: ['modal', 'item', 'errors'],
        data() {
            return {
                roles: [],
                otherUserProperties: {},
                registerTypes: [
                    { value: 'invite', name: 'Invite' },
                    { value: 'register', name: 'Register' }
                ],
                selectedRegisterType: 'invite'
            }
        },
        watch: {
            "item.role"() {
                this.getRoleUsersProperties();
            },
            selectedRegisterType() {
                this.modal.setProp('selectedRegisterType', this.selectedRegisterType);
            }
        },
        methods: {
            getListOfRoles() {
                this.ajax('GetListOfRoles', {
                    url: '/roles/list',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.roles = body;
                });
            },
            getRoleUsersProperties() {
                if(!this.item.role) {
                    this.otherUserProperties = {};
                    return;
                }
                this.ajax('GetListOfRoles', {
                    url: `/roles/${this.item.role}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.otherUserProperties = body;
                });
            },
        },
        mounted() {
            this.getListOfRoles();
            this.modal.setProp('selectedRegisterType', this.selectedRegisterType);
        }
    }
</script>

<style lang="scss" scoped>
.save-user-wrapper {
    padding: 20px;
    position: relative;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}


</style>